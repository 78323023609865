import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import AuthLayout from "@/layout/auth/AuthLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
import Dashboard from "@/pages/Dashboard.vue";
import Settings from "@/pages/Settings.vue";
import ChangePassword from "@/pages/Settings/ChangePassword.vue";
import MemberList from "@/pages/Settings/MembersCard.vue";
import CreateCaseManager from "@/pages/Settings/EditProfileForm.vue";
import Notifications from "@/pages/Notifications.vue";
import Icons from "@/pages/Icons.vue";
import Maps from "@/pages/Maps.vue";
import Typography from "@/pages/Typography.vue";
import Reports from "@/pages/Reports.vue";
import ReportsList from "@/pages/Reports/List.vue";
import CallRelatedList from "@/pages/Reports/CallRelatedList.vue";
import ReportsDetails from "@/pages/Reports/Details.vue";
import CasesList from "@/pages/Cases/List.vue";
import CasesCreate from "@/pages/Cases/Create.vue";
import Calls from "@/pages/Calls.vue";
import CallsList from "@/pages/Calls/List.vue";
import Analytics from "@/pages/Analytics.vue";
import AnalyticsGraphs from "@/pages/Analytics/Graphs.vue";

// Auth pages
import Login from "@/pages/Auth/Login.vue";
import Signup from "@/pages/Auth/Signup.vue";
import WorkspaceRequest from "@/pages/Auth/Workspace/Request.vue";
import WorkspaceConfirm from "@/pages/Auth/Workspace/Confirm.vue";

import { isSignedIn } from "@/utils/auth";

const signedInCheck = ({ next }) => {
  const result = isSignedIn();
  console.log(`signedInCheck, ${result}`)
  if (result) {
    return next();
  }

  return next({
    name: 'login',
  });
};

const routes = [
  {
    path: "/",
    beforeEnter: (to, from, next) => signedInCheck({ next }),
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard
      },
      {
        path: "settings",
        name: "settings",
        component: Settings,
        children: [{
          path: "/",
          name: "Call Agents",
          component: MemberList
        }, {
            path: "password",
            name: "Change My Password",
            component: ChangePassword
        }, {
            path: "create/:id?",
            name: "Create Call Agent",
            component: CreateCaseManager
        },
          {
            path: "password/change",
            name: "Change Password",
            component: ChangePassword
          }]
      },
      {
        path: "notifications",
        name: "notifications",
        component: Notifications
      },
      {
        path: "icons",
        name: "icons",
        component: Icons
      },
      {
        path: "maps",
        name: "maps",
        component: Maps
      },
      {
        path: "typography",
        name: "typography",
        component: Typography
      },
      {
        path: "analytics",
        name: "Analytics",
        component: Analytics,
        children: [{
          path: "/",
          name: "Graphs",
          component: AnalyticsGraphs
        }],
      },
      {
        path: "reports",
        name: "reports",
        component: Reports,
        children: [{
          path: "/",
          name: "Reports",
          component: ReportsList
        }, {
            path: "related",
            name: "Reports related to call",
            component: CallRelatedList
        }, {
          path: "details/:id",
          name: "Case Details",
          component: ReportsDetails
        }]
      },
      {
        path: "calls",
        name: "Calls",
        redirect: "calls/logs",
        component: Calls,
        children: [{
          path: "logs",
          name: "Call Logs",
          component: CallsList
        }]
      },
      {
        path: "cases",
        redirect: "/cases/list"
      },
      {
        path: "cases/list",
        name: "my cases",
        component: CasesList
      },
      {
        path: "cases/:type/:id",
        name: "Case Details",
        component: ReportsDetails
      },
      {
        path: "create",
        name: "add new case",
        component: CasesCreate
      },
    ]
  },
  {
    path: "/user",
    component: AuthLayout,
    redirect: "/user/login",
    children: [
      {
        path: "login",
        name: "login",
        component: Login,
        beforeEnter: (to, from, next) => {
          if (isSignedIn()) {
            return next({ path: "/dashboard" });
          }
          return next();
        },
      },
      {
        path: "register",
        name: "create workspace",
        component: WorkspaceRequest,
      },
      {
        path: "workspace/add/:token",
        name: "complete workspace setup",
        component: WorkspaceConfirm,
      },
      {
        path: "signup/:token",
        name: "complete counsellor signup",
        component: Signup
      },
    ],
  },
  { path: "*", component: NotFound }
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
