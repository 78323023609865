import invoke from './invoke';

export const fetchStateLgaData = async ({ stateIso2 }) => {
    try {
        const res = await invoke('GET', `/state/lga/${stateIso2}`);
        return res;
    } catch (ex) {
        throw ex;
    }
};
