<template>
  <div class="col-xl-10 col-lg-7 col-md-6">
    <p-button type="info"
              class="backBtn"
              round
              @click.native.prevent="$router.push('/settings')">
      Back
    </p-button><br>
    <card class="card" :title="$route.params.id ? 'Change Agent Info' : 'Send Invite'">
      <div>
        <form @submit.prevent>
          <div class="row">
            <div class="col-md-12">
              <fg-input type="email"
                        label="Email"
                        required
                        placeholder="e.g john@email.com"
                        v-model="user.newCounsellorEmail">
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <fg-input type="text"
                        required
                        label="Name"
                        v-model="user.newCounsellorName">
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <fg-input type="text"
                        required
                        label="Password"
                        v-model="user.newPassword">
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <fg-input type="text"
                        label="Phone Number"
                        required
                        v-model="user.newCounsellorPhone">
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <fg-input-dropdown type="text"
                                 label="Gender"
                                 placeholder="E.g Female"
                                 required
                                 :options="genderOptions"
                                 v-model="user.newCounsellorGender">
              </fg-input-dropdown>
            </div>
            <div class="col-md-6">
              <fg-input-dropdown type="text"
                                 label="Role"
                                 placeholder="Role"
                                 required
                                 :options="roleOptions"
                                 v-model="user.newCounsellorRole">
              </fg-input-dropdown>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Qualifications</label>
                <textarea rows="5" class="form-control border-input"
                          placeholder="Qualifications"
                          v-model="user.newCounsellorQualification">
                </textarea>
              </div>
            </div>
          </div>

          <div v-if="uploadProfileError" class="row">
            <div class="col-md-12">
              <div class="alert alert-danger">
                <button type="button" aria-hidden="true" class="close" v-on:click="closeAlert">×</button>
                <span>{{uploadProfileError}}</span>
              </div>
            </div>
          </div>


          <div class="text-center">
            <p-button type="success"
                      round
                      :loading="isSignupInvite"
                      @click.native.prevent="updateProfile">
              {{$route.params.id ? 'Update Agent' : 'Create Agent'}}
            </p-button>
          </div>
          <div class="clearfix"></div>
        </form>
      </div>
    </card>
  </div>
</template>
<script>
import {
  mapState,
  mapActions,
} from 'vuex';
import { getCounsellor } from "@/utils/auth";
export default {
  data() {
    return {
      uploadProfileError: null,
      user: {
        workspaceId: null,
        requesterId: null,
        emailToUpdate: null,
        newPassword: null,
        newCounsellorName: "",
        newCounsellorRole: "",
        newCounsellorEmail: "",
        newCounsellorPhone: "",
        newCounsellorGender: "",
        newCounsellorQualification: ""
      },
      genderOptions: [
        {key: 0, value: "Female", text: "Female"},
        {key: 1, value: "Male", text: "Male"},
      ],
      roleOptions: [
        {key: 0, value: "Administrator", text: "Administrator"},
        {key: 1, value: "Call Handler", text: "Call Handler"}
      ],
    };
  },
  async mounted() {
    const counsellor = getCounsellor();
    this.user.workspaceId = counsellor.workspaceId;
    this.user.requesterId = counsellor.counsellorId;
    this.user.newCounsellorPhone = this.user.newCounsellorPhone.toString();

    if (this.$route.params.id) {
      let foundUser;
      if (this.counsellorList.length !== 0) {
        await this.fetchCounsellorList({
          page: 0,
          count: 100,
          workspaceId: counsellor.workspaceId
        });
        foundUser = this.counsellorList.find((q) => q.id === parseInt(this.$route.params.id, 10));
      }
      this.user = {
        ...this.user,
        newCounsellorName: foundUser.name,
        newCounsellorRole: foundUser.role,
        newCounsellorEmail: foundUser.email,
        emailToUpdate: foundUser.email,
        newPassword: "",
        newCounsellorPhone: foundUser.phone.toString(),
        newCounsellorGender: foundUser.gender,
        newCounsellorQualification: foundUser.qualification
      };
    }
  },
  computed: {
    ...mapState('auth', [
      'signupInvite', 'isSignupInvite', 'counsellorList'
    ]),
  },
  methods: {
    ...mapActions('auth', [
      'fetchCounsellorList', 'sendCounsellorInvite', 'updateMemberInfo'
    ]),
    closeAlert() {this.uploadProfileError = null},
    async updateProfile() {
      try {
        this.uploadProfileError = null;
        if (this.$route.params.id) {
          const params = {
            workspaceId: this.user.workspaceId,
            requesterId: this.user.requesterId,
            emailToUpdate: this.user.emailToUpdate,
            newName: this.user.newCounsellorName,
            newGender: this.user.newCounsellorGender,
            newEmail: this.user.newCounsellorEmail,
            newPhone: [this.user.newCounsellorPhone],
            newQualification: this.user.newCounsellorQualification,
            newRole: this.user.newCounsellorRole,
            disable: this.user.disable,
          }
          if (this.user.newPassword) params.newPassword = this.user.newPassword;
          Object.keys(params).forEach((key) => (params[key] === null || params[key] === undefined || params[key] === '') && delete params[key]);
          console.log({params})
          await this.updateMemberInfo(params);
          this.$notify({
            message: 'Team member updated successfully',
            icon: "ti-check-box",
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success'
          });
        } else {
          const params = {...this.user};
          params.newCounsellorPhone = [this.user.newCounsellorPhone];
          await this.sendCounsellorInvite(params);
          this.$notify({
            message: 'Team member invited successfully',
            icon: "ti-check-box",
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success'
          });
        }
        await this.$router.push('/settings')
      } catch (ex) {
        this.uploadProfileError = ex;
        console.log(`error, ${ex}`);
        this.$notify({
          message: 'Error updating team member',
          icon: "ti-alert",
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger'
        });
      }
    },
  }
};
</script>
<style lang="scss">
.backBtn{
  margin-bottom: 10px;
}
</style>
