import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import cases from './modules/cases';
import state from './modules/state';
import calls from './modules/calls';
import voiceClient from './modules/voiceClient';
import analytics from './modules/analytics';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
    modules: {
        auth,
        cases,
        state,
        calls,
        voiceClient,
        analytics
    },
    strict: debug,
});

export default store;
