import {
  fetchCallLogsData,
  exportCallLogsData
} from '@/utils/api/calls';

const defaultState = {
    logs: [],
    isFetchingLogs: false,
    isDownloadingLogs: false,
};

const mutations = {
    setIsFetchingLogs(state, status) {
        state.isFetchingLogs = status;
    },
    setIsDownloadingLogs(state, status) {
        state.isDownloadingLogs = status;
    },
    setLogs(state, logs) {
        state.logs = logs;
    }
};

const actions = {
    async fetchCallLogs({ commit }, data) {
        try {
            commit('setIsFetchingLogs', true);
            const results = await fetchCallLogsData(data);
            commit('setLogs', results.entries);
            commit('setIsFetchingLogs', false);
        } catch (ex) {
            commit('setIsFetchingLogs', false);
            throw ex;
        }
    },

  async downloadCallLogs({ commit }, data) {
    try {
      commit('setIsDownloadingLogs', true);
      await exportCallLogsData(data);
      commit('setIsDownloadingLogs', false);
    } catch (ex) {
      commit('setIsDownloadingLogs', false);
      throw ex;
    }
  },
};

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
};
