<template>
  <div class="col-12">
    <div class="row">
      <calls-pie-graph/>
      <calls-duration-pie-graph/>
    </div>
    <div class="row">
      <calls-bar-graph/>
      <calls-line-graph/>
    </div>
    <div class="row">
      <call-count-graph/>
      <call-duration-graph/>
      <call-duration-bar-graph/>
    </div>
  </div>
</template>
<script>

import CallsBarGraph from "@/pages/Analytics/BarChart/Calls.vue";
import CallsLineGraph from "@/pages/Analytics/LineChart/Calls.vue";
import CallsPieGraph from "@/pages/Analytics/PieChart/Calls.vue";
import CallsDurationPieGraph from "@/pages/Analytics/PieChart/CallsDuration.vue";
import CallCountGraph from "@/pages/Analytics/LineChart/CallCount.vue";
import CallDurationGraph from "@/pages/Analytics/LineChart/CallDuration.vue";
import CallDurationBarGraph from "@/pages/Analytics/BarChart/CallDuration.vue";

export default {
  components: {
    CallsPieGraph,
    CallsDurationPieGraph,
    CallsBarGraph,
    CallsLineGraph,
    CallCountGraph,
    CallDurationGraph,
    CallDurationBarGraph
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  async mounted() {
  },
  async created() {
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
  .menu.dropdown {
    margin-bottom: 15px
  }
</style>
