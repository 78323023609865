<template>
  <table class="table" :class="tableClass">
    <thead>
    <slot name="columns">
      <th v-for="(key, idx) in Object.keys(columns)" :key="idx">{{Object.values(columns[key])[0].toString()}}</th>
    </slot>
    </thead>
    <tbody>
    <tr v-if="loading">
     <td :colspan="Object.keys(columns).length" style="text-align: center;"><i class="fa fa-spinner fa-spin fa-fw fa-2x"></i></td>
    </tr>
    <tr v-else-if="data.length === 0">
     <td :colspan="Object.keys(columns).length" style="text-align: center;">No Records Found</td>
    </tr>
    <tr v-else v-for="(item, idx) in data" :key="idx">
      <slot :row="item">
        <td v-for="(key, idx) in Object.keys(columns)" :key="idx">{{findColumnData(item, key)}}</td>
      </slot>
    </tr>
    </tbody>
  </table>
</template>
<script>
import moment from 'moment';
export default {
  name: 'paper-table',
  props: {
    columns: Array,
    data: Array,
    type: {
      type: String, // striped | hover
      default: "striped"
    },
    title: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    subTitle: {
      type: String,
      default: ""
    }
  },
  computed: {
    tableClass() {
      return `table-${this.type}`;
    }
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    findColumnData(item, key){
      let result = 'N/A';
      if (item) {
        result = item[Object.keys(this.columns[key]).toString()];
        if (this.columns[key].type) {
          if (this.columns[key].type === 'date') {
            result = moment(result).format('MMMM Do YYYY, h:mm:ss a')
          }
        }
      }
      return result;
    }
  }
};
</script>
<style>
</style>
