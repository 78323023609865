<template>
  <card class="card">
    <div>
      <form id="loginForm" @submit.prevent="processForm">

        <div class="row">
          <div class="col-md-12">
            <fg-input type="text"
                        required
                      label="Workspace name"
                      placeholder="myWorkspace"
                      v-model="user.workspaceName">
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <fg-input type="email"
                        required
                      label="Email Address"
                      placeholder="john@doe.com"
                      v-model="user.email">
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <fg-input type="password"
                        required
                      label="Password"
                      placeholder="mysecretpassword"
                      v-model="user.password">
            </fg-input>
          </div>
        </div>

        <div v-if="signinError" class="row">
          <div class="col-md-12">
            <div class="alert alert-danger">
                <button type="button" aria-hidden="true" class="close" v-on:click="closeAlert">×</button>
                <span>{{signinError}}</span>
            </div>
          </div>
        </div>

        <div class="text-center">
          <p-button type="success"
                    block
                    :loading="isSigningIn"
                    nativeType="submit">
            Login
          </p-button>
        </div>
        <div class="text-right">
            <router-link to="/user/register">Create new Workspace</router-link>
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </card>
</template>
<script>
import {
    mapState,
    mapActions,
} from 'vuex';
export default {
  data() {
    return {
    signinError: null,
    user: {
        workspaceName: null,
        email: null,
        password: null,
      }
    };
  },
computed: {
    ...mapState('auth', [
        'isSigningIn',
    ]),
},
  methods: {
     ...mapActions('auth', [
            'signIn',
        ]),

        closeAlert() {this.signinError = null},

        async processForm() {
            try {
                this.signinError = null;
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.user.email)) {
                    this.signinError = `Email address is not valid`;
                    return;
                }

                await this.signIn(this.user);
                // setAccessToken(accessToken);
                this.$router.push('/dashboard');
            } catch (ex) {
                this.signinError = ex;
                console.log(`error, ${ex}`);
            }
        },
  }
};
</script>
<style lang="scss" scoped>
    .card{
        box-shadow: none;
    }
</style>
