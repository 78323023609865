<template>
    <div class="col-12">
        <div class="row">
            <div class="col-md-3">
                <fg-input type="text"
                        placeholder="Search Logs"
                        label="Search"
                        v-model="search">
                </fg-input>
            </div>
          <div class="col-6 side-actions">
            <div class="dropdown menu">
              <button class="btn btn-round btn-info dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Export
              </button>
              <div class="dropdown-menu dropdown-menu-left p4">
                <form class="col-12" @submit.prevent>
                  <date-range-picker
                    v-model="dateRange"
                    ref="picker"
                    :minDate="minDate"
                    :maxDate="maxDate"
                    opens="left"
                    :locale-data="locale"
                    @update="updateValues"
                  >
                    <template v-slot:input="picker" style="min-width: 50px;">
                      {{ picker.startDate | date }} - {{ picker.endDate | date }}
                    </template>
                  </date-range-picker>
                  <br>
                </form>
              </div>
            </div>
          </div>
        </div>
        <card title="Call Logs" subTitle="Call Logs breakdown">
            <div slot="pagination">
                <a class="btn btn-just-icon btn-outline-default btn-sm" v-on:click="fetchWithFilter">
                <i class="ti-reload"></i>
                </a>
                &nbsp;
                &nbsp;
                &nbsp;
                page {{filter.page}} of {{(filter.page * filter.count)}}
                &nbsp;
                <a class="btn btn-just-icon btn-outline-default btn-sm" :disabled="filteredLogs.page <= 0" v-on:click="prevPage">
                <i class="ti-angle-left"></i>
                </a>
                &nbsp;
                <a class="btn btn-just-icon btn-outline-default btn-sm" :disabled="filteredLogs.length !== filter.count" v-on:click="nextPage">
                <i class="ti-angle-right"></i>
                </a>
            </div>
            <div slot="raw-content" class="table-responsive">
            <!-- <paper-table :data="logs" :columns="tableColumns" :loading="isFetchingLogs" /> -->
            <table class="table">
                <thead>
                    <th>Call Handler</th>
                    <th>Direction</th>
                    <th>Virtual Number</th>
                    <th>Caller Number</th>
                    <th>Call Start Time</th>
                    <th>Duration</th>
                    <th>Hangup Cause</th>
                </thead>
                <tbody>
                <tr v-if="isFetchingLogs">
                    <td colspan="8" style="text-align: center;"><i class="fa fa-spinner fa-spin fa-fw fa-2x"></i></td>
                    </tr>
                    <tr v-else-if="filteredLogs.length === 0">
                    <td colspan="8" style="text-align: center;">No Call Logs Found</td>
                    </tr>
                    <tr v-else v-for="(item, idx) in filteredLogs" :key="idx">
                    <slot :row="item">
                        <td>{{item.callHandlerName}}</td>
                        <td>{{item.direction}}</td>
                        <td>{{item.destinationNumber}}</td>
                        <td>
                            <span class="caller-number" v-on:click="callNumber(item)">{{item.callerNumber}}</span>
                        </td>
                        <td>{{item.callStartTime | formatDate}}</td>
                        <td>{{item.durationInSeconds}} sec</td>
                        <td>{{item.hangupCause}}</td>
                    </slot>
                    </tr>
                </tbody>
            </table>
            </div>
        </card>
    </div>
</template>
<script>
import moment from 'moment';
import {
    mapState,
    mapActions,
} from 'vuex';
import { EventBus } from "@/utils";
import { getCounsellor } from "@/utils/auth";
import {isMoreThanAMonth, isOneDay, minDate, nowDate} from "@/utils/utils";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {filter} from "vue2-daterange-picker/dist/vue2-daterange-picker.common";
export default {
  components: {
    DateRangePicker
  },
  data() {
    return {
      exportCount: 10000,
      minDate: minDate(),
      maxDate: nowDate(),
      dateRange: {
        startDate: nowDate(),
        endDate: nowDate()
      },
      locale: {
        direction: 'rtr',
        format: 'mm-dd-yyyy',
        separator: ' to ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
        firstDay: 1
      },
      counsellor: null,
      config: {
            format: '',
            useCurrent: false,
            showClear: true,
            showClose: true,
        },
        search: null,
        filteredLogs: [],
        filter: {
            workspaceId: null,
            page: 1,
            count: 50,
            counsellorId: null,
        },
    };
  },
  computed: {
    ...mapState('calls', [
        'logs', 'isFetchingLogs', 'isDownloadingLogs'
    ]),
  },
  watch: {
      search(newValue){
          if (newValue && newValue !== '') {
            this.filteredLogs = this.logs.filter(report=> {
              return report.nature.toLowerCase().includes(newValue) || report.status.toLowerCase().includes(newValue)
              || report.referredBy.toLowerCase().includes(newValue) || report.stateIso2.toLowerCase().includes(newValue)
              || report.lgaOfOccurrence.toLowerCase().includes(newValue)
          });
        } else {
            this.filteredLogs = this.logs;
        }
      },
      logs(newValue) {
          console.log({logs:this.logs})
          this.filteredLogs = newValue;
      }
  },
  filters: {
    date(val) {
      return val ? moment(val).format('LL') : "";
    },
    formatDate(date) {
      let result = 'N/A';
      if (date) result = moment(date).format('MMMM Do YYYY, h:mm:ss a')
      return result;
    }
  },
  async created() {
    const counsellor = getCounsellor();
    this.counsellor = counsellor;
    this.filter.workspaceId = this.counsellor.workspaceId;
    this.filter.counsellorId = counsellor.counsellorId;
    await this.fetchWithFilter();
  },
    methods: {
        ...mapActions('calls', [
            'fetchCallLogs', 'downloadCallLogs'
        ]),
      updateValues({ startDate, endDate }) {
        this.filter.startDate = startDate;
        this.filter.endDate = endDate;
        this.downloadWithFilter();
      },
        prevPage() {
          if (this.filter.page > 0) {
            this.filter.page -= 1;
            this.fetchWithFilter();
          }
        },

        callNumber(item) {
            console.log({item})
            if (this.counsellor.role === 'Administrator' || this.counsellor.role === 'Call Handler') {
                EventBus.$emit('makeCall', item.callerNumber);
                window.scrollTo(0,0)
            }
        },
        nextPage() {
          if (this.logs.length === this.filter.count) {
            this.filter.page += 1;
            this.fetchWithFilter();
          }
        },
        async fetchWithFilter() {
          const params = this.filter;
          Object.keys(params).forEach((key) => (params[key] === null || params[key] === '') && delete params[key]);
          await this.fetchCallLogs(params);
        },
      async downloadWithFilter() {
        const params = {...this.filter};
        Object.keys(params).forEach((key) => (params[key] === null || params[key] === '') && delete params[key]);

        if (isOneDay(params.startDate, params.endDate)) {
          const stateDate = moment(params.startDate)
          const endDate = moment(params.endDate)
          params.startDate = stateDate.startOf('day').valueOf()
          params.endDate = endDate.endOf('day').valueOf()
        }
        else if (isMoreThanAMonth(params.startDate, params.endDate)) {
          const stateDate = moment(params.startDate)
          const endDate = moment(params.endDate)
          params.startDate = stateDate.startOf('month').valueOf()
          params.endDate = endDate.endOf('month').valueOf()
        }
        else {
          const stateDate = moment(params.startDate)
          const endDate = moment(params.endDate)
          params.startDate = stateDate.startOf('day').valueOf()
          params.endDate = endDate.endOf('day').valueOf()
        }
        params.count     = this.exportCount;
        params.startDate = moment(params.startDate).format("YYYY-MM-DD")
        params.endDate   = moment(params.endDate).format("YYYY-MM-DD")
        try {
          this.$notify({
            message: 'Started downloading call logs... \n Please wait...',
            icon: "ti-check-box",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'info'
          });
          await this.downloadCallLogs(params);
          this.$notify({
            message: 'Call logs downloaded successfully. \nPlease check your email for the call logs',
            icon: "ti-check-box",
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success'
          });
        } catch (e) {
          console.log(e);
          this.$notify({
            message: 'Error downloading report. \nPlease try again later',
            icon: "ti-alert",
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'danger'
          });
        }
      },
  }
};
</script>
<style lang="scss" scoped>
.caller-number{
    color: #68B3C8;
    cursor: pointer;
    &:hover{
        color: #3091B2;
    }
}
.side-actions{
    display: flex;
    align-items: flex-end;
    margin-left: auto;
    flex-direction: row-reverse;
    vertical-align: baseline;
    .dropdown{
        margin-bottom: 15px;
    }
}
.dropdown-menu{
    background-color: #fff !important;
    padding: 15px 0px !important;
    width: 300px;
}
</style>
