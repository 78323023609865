<template>
      <div class="col-12">
        <chart-card title="Total Call Count Line Chart"
                    subTitle="Call Count Grouped By Call Handler"
                    v-if="!isFetchingCallCount"
                    :chart-data="data"
                    :chart-options="options"
                    footer-text='Last Two weeks (Default)'
        >
                    <div v-if="!data || data.series.length === 0" slot="empty">No Data Available</div>
                    <div class="row" slot="filter">
                        <div class="col-6">
                                    <div class="dropdown menu">
                                        <button class="btn btn-round btn-info dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Filter
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-left p4">
                                            <form class="col-12" @submit.prevent>
                                              <date-range-picker
                                                v-model="dateRange"
                                                ref="picker"
                                                :minDate="minDate"
                                                :maxDate="maxDate"
                                                opens="right"
                                                :locale-data="locale"
                                                @update="updateValues"
                                              >
                                                <template v-slot:input="picker" style="min-width: 50px;">
                                                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                                </template>
                                              </date-range-picker>
                                            <br>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                        </div>
        </chart-card>
      </div>
</template>
<script>
import {
    mapState,
    mapActions,
} from 'vuex';
import moment from 'moment';
import Chartist from 'chartist';
require('chartist-plugin-legend');
import ChartistTooltip from 'chartist-plugin-tooltips-updated';
import { ChartCard } from "@/components/index";
import { getCounsellor } from '@/utils/auth'
import {
  isMoreThanAMonth,
  isOneDay,
  minDate,
  nowDate,
  twoWeekEndDate,
  twoWeekStartDate
} from "@/utils/utils";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
export default {
  components: {
    ChartCard,
    DateRangePicker
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data() {
    return {
      counsellor: null,
      minDate: minDate(),
      maxDate: nowDate(),
      dateRange: {
        startDate: twoWeekStartDate(),
        endDate: twoWeekEndDate()
      },
      locale: {
        direction: 'ltr',
        format: 'mm-dd-yyyy',
        separator: ' to ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
        firstDay: 1
      },
      config: {
        format: '',
        useCurrent: false,
        showClear: true,
        showClose: true,
      },
      legendNames: [],
      filter: {
        metrics: [{
            name: 'TotalCalls',
            tags: {
              workspaceId: []
            },
            aggregators: [
              {
                name: "sum",
                sampling: {
                  value: "1",
                  unit: "hours"
                },
                align_end_time: true
              }
            ]
        }],
        plugins: [],
        cache_time: 0,
        start_absolute: twoWeekStartDate(),
        end_absolute: twoWeekEndDate(),
      },
      countLineData: null,
      data: null,
      options: null,
    };
  },
  filters: {
    date(val) {
      return val ? moment(val).format('LL') : "";
    }
  },
  computed: {
    ...mapState('analytics', ['callCount', 'isFetchingCallCount']),
    ...mapState("auth", ["counsellorList", "isFetchingCounsellorList"]),
  },
  watch: {
    async callCount(newValue) {
      await this.formatGraphData(newValue);
    }
  },
  async mounted() {

  },
  async created() {
    this.counsellor = getCounsellor();
    await this.fetchWithFilter()
  },
  methods: {
    ...mapActions('analytics', [
      'fetchCallCount',
    ]),
    updateValues({ startDate, endDate }) {
      this.filter.start_absolute = startDate;
      this.filter.end_absolute = endDate;
      this.fetchWithFilter();
    },
    async fetchWithFilter() {
      if (isOneDay(this.filter.start_absolute, this.filter.end_absolute)) {
        const stateDate = moment(this.filter.start_absolute)//.tz('Europe/London');
        const endDate = moment(this.filter.end_absolute)//.tz('Europe/London');
        this.filter.start_absolute = stateDate.startOf('day').valueOf()
        this.filter.end_absolute = endDate.endOf('day').valueOf()
        this.filter.metrics.map(metric => {
          metric.aggregators[0].sampling = {
            value: '1',
            unit: 'hours',
          };
          metric.tags.workspaceId = [this.counsellor.workspaceId]
          return metric
        })
      }
      else if (isMoreThanAMonth(this.filter.start_absolute, this.filter.end_absolute)) {
        const stateDate = moment(this.filter.start_absolute)//.tz('Europe/London');
        const endDate = moment(this.filter.end_absolute)//.tz('Europe/London');
        this.filter.start_absolute = stateDate.startOf('month').valueOf()
        this.filter.end_absolute = endDate.endOf('month').valueOf()
        this.filter.metrics.map(metric => {
          metric.aggregators[0].sampling = {
            value: '1',
            unit: 'months',
          };
          metric.tags.workspaceId = [this.counsellor.workspaceId]
          return metric
        })
      }
      else {
        const stateDate = moment(this.filter.start_absolute)//.tz('Europe/London');
        const endDate = moment(this.filter.end_absolute)//.tz('Europe/London')
        this.filter.start_absolute = stateDate.startOf('day').valueOf()
        this.filter.end_absolute = endDate.endOf('day').valueOf()
        this.filter.metrics.map(metric => {
          metric.aggregators[0].sampling = {
            value: '1',
            unit: 'days',
          };
          metric.tags.workspaceId = [this.counsellor.workspaceId]
          return metric
        })
      }

      const params = this.filter;
      Object.keys(params).forEach((key) => (params[key] === null || params[key] === '') && delete params[key]);

      //console.log("PARAM CALL COUNT " + JSON.stringify(params))
      await this.fetchCallCount(params);
    },
    getExpectedTimeStamps (start, end) {
      const expectedTimeStamps = [];
      const startDate          = moment(start);
      const endDate            = moment(end);
      if (isOneDay(this.filter.start_absolute, this.filter.end_absolute)) {
        while (startDate.isBefore(endDate)) {
          expectedTimeStamps.push(startDate.format('h:mm a'));
          startDate.add(1, 'hours');
        }
      }
      else if (isMoreThanAMonth(this.filter.start_absolute, this.filter.end_absolute)) {
        while (startDate.isBefore(endDate)) {
          expectedTimeStamps.push(startDate.format('MMMM YYYY'));
          startDate.add(1, 'months');
        }
      }
      else {
        while (startDate.isBefore(endDate)) {
          expectedTimeStamps.push(startDate.format('YYYY-MM-DD'));
          startDate.add(1, 'days');
        }
      }
      return expectedTimeStamps;
    },
    async formatGraphData() {
      this.data = {
        labels: [],
        series: []
      }
      this.legendNames =[]
      this.options = {
          low: 0,
          showArea: true,
          height: "350px",
          axisX: {
            showGrid: true
          },
          axisY: {
            onlyInteger: true
          },
          lineSmooth: Chartist.Interpolation.simple({
            divisor: 3
          }),
          labelOffset: 70,
          labelDirection: 'explode',
          showLine: true,
          plugins: [
            Chartist.plugins.tooltip(),
            ChartistTooltip()
          ]
        }
      const expectedTimeStamps = this.getExpectedTimeStamps(
        this.filter.start_absolute,
        this.filter.end_absolute
      );
      expectedTimeStamps.forEach((timestamp, idx) => {
        if (!this.data.labels.includes(timestamp)) this.data.labels.push(timestamp);
      });
      //console.log("EXPECTED FOR TOTAL CALL COUNTS ", expectedTimeStamps)
      this.callCount.queries.forEach(query => {
        query.results.forEach((result, idx) => {
          switch (result.name) {
            case 'TotalCalls':
              const resultTimestamps = result.values.map(value => {
                //console.log("VALUE ", value[0], moment(value[0]).format('YYYY-MM-DD'), value[1])
                if (isOneDay(this.filter.start_absolute, this.filter.end_absolute)) {
                  return moment(value[0]).subtract(1, "hours").format('h:mm a')
                }
                else if (isMoreThanAMonth(this.filter.start_absolute, this.filter.end_absolute)) {
                  return moment(value[0]).subtract(1, 'months').format('MMMM YYYY')
                }
                else {
                  return moment(value[0]).subtract(1, 'day').format('YYYY-MM-DD')
                }
              });

              //console.log("RESULT FOR TOTAL CALL COUNTS ", resultTimestamps)

              const resultMap = result.values.reduce((map, value) => {
                if (isOneDay(this.filter.start_absolute, this.filter.end_absolute)) {
                  map[moment(value[0]).subtract(1, "hours").format('h:mm a')] = value[1];
                  return map;
                }
                else if (isMoreThanAMonth(this.filter.start_absolute, this.filter.end_absolute)) {
                  map[moment(value[0]).subtract(1, 'months').format('MMMM YYYY')] = value[1]
                  return map;
                }
                else {
                  map[moment(value[0]).subtract(1, 'days').format('YYYY-MM-DD')] = value[1]
                  return map;
                }
              }, {});

              //console.log("RESULT MAP ", JSON.stringify(resultMap))

              const elements = [];
              expectedTimeStamps.forEach((timestamp, idx) => {
                if (resultTimestamps.includes(timestamp)) {
                  elements.push(resultMap[timestamp]);
                } else {
                  elements.push(0);
                }
              });
              this.data.series.push(elements);
            break;
            default:
              break;
          }
        })
        // console.log({ data: this.data})
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .menu.dropdown {
    margin-bottom: 15px
  }
</style>

