import Vue from 'vue';

export const APP_CONFIG = JSON.parse(process.env.VUE_APP_CONFIG);

export const EventBus = new Vue();

export const formatQueryParams = query => Object
    .entries(query)
    .reduce((acc, [param, value]) => `${acc}${param}=${encodeURIComponent(value)}&`, '?')
    .replace(/&$/, '');

export const getTimeRemaining = (endtime) => {
    const total = Date.parse(endtime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
        total,
        days,
        hours,
        minutes,
        seconds
    };
}