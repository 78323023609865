<template>
<div class="col-6">
    <card class="card"  title="Survivor Information" subTitle="Add Information about the survivor">
            <div class="row">
                <div class="col-md-12">
                    <fg-input type="text"
                            required
                            :error="error.name"
                            label="Survivor's name"
                            v-model="survivorInfo.name">
                    </fg-input>
                </div>
            </div>
             <div class="row">
                <div class="col-md-12">
                    <fg-input type="text"
                            required
                            :error="error.address"
                            label="Address"
                            v-model="survivorInfo.address">
                    </fg-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <fg-input-dropdown type="text"
                        label="Marital Status"
                        placeholder="e.g Single"
                        required
                        :error="error.maritalStatus"
                        :options="maritalOptions"
                        v-model="survivorInfo.maritalStatus">
                    </fg-input-dropdown>
                </div>
                <div class="col-md-6">
                    <fg-input-dropdown type="text"
                        label="Employment Status"
                        placeholder="e.g Employed"
                        :error="error.employmentStatus"
                        required
                        :options="employStatusOptions"
                        v-model="survivorInfo.employmentStatus">
                    </fg-input-dropdown>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <fg-input type="text"
                            required
                            label="Phone Number"
                            :error="error.phone"
                            v-model="survivorInfo.phone">
                    </fg-input>
                </div>
                <div class="col-md-6">
                    <fg-input type="number"
                            required
                            label="Age"
                            :error="error.age"
                            v-model="survivorInfo.age">
                    </fg-input>
                </div>
            </div>
    </card>
</div>
</template>
<script>
import { getCounsellor } from "@/utils/auth";
export default {
    data() {
        return {
            survivorInfo: {
                age: null,
                address: "",
                employmentStatus: "",
                maritalStatus: "",
                name: "",
                phone: ""
            },
            genderOptions: [
                {key: 1, value: "Female", text: "Female"},
                {key: 0, value: "Male", text: "Male"},
            ],
            maritalOptions: [
                {key: 0, value: "Single", text: "Single"},
                {key: 1, value: "Married", text: "Married"},
                {key: 2, value: "Divorced", text: "Divorced"},
                {key: 3, value: "Other", text: "Other"},
            ],
            employStatusOptions: [
                {key: 0, value: "Employed",text: "Employed"},
                {key: 0, value: "Unemployed",text: "Unemployed"},
                {key: 0, value: "SelfEmployed",text: "Self Employed"},
                {key: 0, value: "Student",text: "Student"}],
        };
    },
    props: {
        updateForm: {
            type: Function,
            required: true,
        },
        formTab: {
            type: String,
            required: true,
        },
        data: {
            type: Object,
            required: false,
        },
        error: {
            type: Object,
            required: true,
        }
    },
    watch: {
        survivorInfo: {
            deep: true,
            handler(newValue) {
                if (newValue.age && typeof newValue.age === 'string') newValue.age = Number(newValue.age);
                this.updateForm(2, newValue);
            },
        },
    },
    mounted() {
        this.survivorInfo = {...this.survivorInfo, ...this.data};
    }
};
</script>
<style>
</style>
