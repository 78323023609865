import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

const getDomain = () => window.location.hostname;

const COOKIES = {
    TOKEN: '_agat_',
};

export const setAccessToken = (token) => {
    Cookies.set(COOKIES.TOKEN, token, {
        domain: getDomain(),
    });
};

export const getAccessToken = () => Cookies.get(COOKIES.TOKEN);

export const deleteAccessToken = () => Cookies.remove(COOKIES.TOKEN, {
    domain: getDomain(),
});

export const isSignedIn = () => {
    try {
        // const user = jwtDecode(Cookies.get(COOKIES.TOKEN));
        const user = Cookies.get(COOKIES.TOKEN);
        return (!!user);
        // return true;
    } catch (ex) {
        console.log({ ex })
        return false;
    }
};

// export const getCounsellor = () => jwtDecode(Cookies.get(COOKIES.TOKEN));
export const getCounsellor = () => JSON.parse(Cookies.get(COOKIES.TOKEN));

export const signOutCleanUp = () => {
    deleteAccessToken();
};
