<template>
<div class="col-6">
    <card class="card" title="Reporter Information" subTitle="Add Information about the reporter">
            <div class="row">
                <div class="col-md-12">
                    <fg-input type="text"
                            label="Name"
                            required
                            :error="error.name"
                            v-model="reporterInfo.name">
                    </fg-input>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <fg-input-dropdown type="text"
                        label="Gender"
                        placeholder="e.g Female"
                        required
                        :error="error.gender"
                        :options="genderOptions"
                        v-model="reporterInfo.gender">
                    </fg-input-dropdown>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <fg-input type="number"
                            label="Age"
                            required
                            :error="error.age"
                            v-model="reporterInfo.age">
                    </fg-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <fg-input type="text"
                            label="Phone Number"
                            required
                            :error="error.phone"
                            v-model="reporterInfo.phone">
                    </fg-input>
                </div>
            </div>
    </card>
</div>
</template>
<script>
import { EventBus } from "@/utils";
import { getCounsellor } from "@/utils/auth";
export default {
  data() {
    return {
        reporterInfo: {
            age: null,
            gender: "",
            name: "",
            phone: "",
            type: "Mandated Reporter"
        },
        genderOptions: [
            {key: 1, value: "Female", text: "Female"},
            {key: 0, value: "Male", text: "Male"},
        ],
    };
  },
  props: {
    updateForm: {
        type: Function,
        required: true,
    },
    formTab: {
        type: String,
        required: true,
    },
    data: {
        type: Object,
        required: false,
    },
    error: {
        type: Object,
        required: true,
    }
  },
    watch: {
        reporterInfo: {
            deep: true,
            handler(newValue) {
                if (newValue.age && typeof newValue.age === 'string') newValue.age = Number(newValue.age);
                this.updateForm(1, newValue);
            },
        },
    },
    mounted() {
        this.reporterInfo = {...this.reporterInfo, ...this.data};
        if (
            this.$route.params &&
            this.$route.params.reporterInfo &&
            this.$route.params.reporterInfo.phone
            ) {
            this.reporterInfo.phone = this.$route.params.reporterInfo.phone;
        }
        EventBus.$on('copyIncomingCallNo', (phone) => {
            if (phone) this.reporterInfo.phone = phone;
            console.log('copyIncomingCallNo v copyIncomingCallNo', this.formData)
        });
    }
};
</script>
<style>
</style>
