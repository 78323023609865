<template>
<div class="col-6">
    <card class="card"  title="Perpetrator Information" subTitle="Add Information about the alleged perpetrator">
            <div class="row">
                <div class="col-md-12">
                    <fg-input type="text"
                            label="Name"
                            :error="error.name"
                            v-model="perpetratorInfo.name">
                    </fg-input>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <fg-input-dropdown type="text"
                        label="Gender"
                        placeholder="e.g Male"
                        :error="error.gender"
                        required
                        :options="genderOptions"
                        v-model="perpetratorInfo.gender">
                    </fg-input-dropdown>
                </div>
                <div class="col-md-6">
                    <fg-input-dropdown type="text"
                        label="Age Range"
                        placeholder="e.g 20-30"
                        :error="error.ageRange"
                        required
                        :options="ageRangeOptions"
                        v-model="perpetratorInfo.ageRange">
                    </fg-input-dropdown>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <fg-input-dropdown type="text"
                        label="Relationship to survivor"
                        placeholder="e.g Friend"
                        :error="error.relationship"
                        required
                        :options="relationshipOptions"
                        v-model="perpetratorInfo.relationship">
                    </fg-input-dropdown>
                </div>
                <div class="col-md-6">
                    <fg-input-dropdown type="text"
                        label="Employment Status"
                        :error="error.employmentStatus"
                        placeholder="e.g Friend"
                        required
                        :options="employStatusOptions"
                        v-model="perpetratorInfo.employmentStatus">
                    </fg-input-dropdown>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <fg-input type="text"
                            label="Phone Number"
                            :error="error.phone"
                            v-model="perpetratorInfo.phone">
                    </fg-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <fg-input type="text"
                            :error="error.address"
                            label="Address"
                            v-model="perpetratorInfo.address">
                    </fg-input>
                </div>
            </div>
    </card>
</div>
</template>
<script>
import { getCounsellor } from "@/utils/auth";
export default {
    data() {
        return {
            perpetratorInfo: {
                address: "",
                ageRange: "",
                employmentStatus: "",
                gender: "",
                name: "",
                phone: "",
                relationship: ""
            },
            genderOptions: [
                {key: 0, value: "Female", text: "Female"},
                {key: 1, value: "Male", text: "Male"},
            ],
            ageRangeOptions: [
                {key: 0, value: "10-19",text: "10-19"},
                {key: 1, value: "20-30",text: "20-30"},
                {key: 2, value: "31-40",text: "31-40"},
                {key: 3, value: "41-60",text: "41-60"},
                {key: 4, value: "61-80",text: "61-80"},
                {key: 5, value: "81-100",text: "81-100"}
            ],
            relationshipOptions: [
                {key: 0, value: "Parent", text: "Parent"},
                {key: 1, value: "Husband", text: "Husband"},
                {key: 2, value: "Wife", text: "Wife"},
                {key: 3, value: "Brother", text: "Brother"},
                {key: 4, value: "Sister", text: "Sister"},
                {key: 5, value: "Uncle", text: "Uncle"},
                {key: 6, value: "Aunty", text: "Aunty"},
                {key: 7, value: "Friend", text: "Friend"},
                {key: 8, value: "Family", text: "Family"},
                {key: 9, value: "Other", text: "Other"},
            ],
            employStatusOptions: [
                {key: 1, value: "Employed",text: "Employed"}, 
                {key: 2, value: "Unemployed",text: "Unemployed"}, 
                {key: 3, value: "SelfEmployed",text: "Self Employed"}, 
                {key: 4, value: "Student",text: "Student"},
                {key: 5, value: "Unknown",text: "Unknown"}],
            referredByOptions: [
                'Social Media', 'Poster', 'Friend', 'Other'
            ]
        };
    },
    props: {
        updateForm: {
            type: Function,
            required: true,
        },
        formTab: {
            type: String,
            required: true,
        },
        data: {
            type: Object,
            required: false,
        },
        error: {
            type: Object,
            required: true,
        }
    },
    created() {
    const counsellor = getCounsellor();
    this.workspaceId = counsellor.workspaceId;
    this.stateIso2 = counsellor.stateIso2
    this.caseManagerId = counsellor.counsellorId;
    },
    mounted() {
        this.perpetratorInfo = {...this.perpetratorInfo, ...this.data};
    },
    watch: {
        perpetratorInfo: {
            deep: true,
            handler(newValue) {
                this.updateForm(3, newValue);
            },
        },
    },
};
</script>
<style>
</style>
