import invoke from './invoke';

export const fetchCallLogsData = async (options) => {
    try {
        const res = await invoke('POST', `/voice/logs`, options);
        return res;
    } catch (ex) {
        throw ex;
    }
};

export const exportCallLogsData = async (options) => {
  try {
    const res = await invoke('POST', `/voice/export`, options);
    return res;
  } catch (ex) {
    throw ex;
  }
};
