<template>
    <div class="col-12">
        <card title="Call Related Reports" subTitle="Case reports based on call">
            <div slot="pagination">
               
            </div>
            <div slot="raw-content" class="table-responsive">
            <!-- <paper-table :data="reports" :columns="tableColumns" :loading="isFetchingReports" /> -->
            <table class="table">
                <thead>
                    <th>Survivor Name</th>
                    <th>Survivor Phone</th>
                    <th>Call Handler</th>
                    <th>Created At</th>
                    <th>Date Of Occurrence</th>
                    <th>LGA Of Occurrence</th>
                    <th>Nature</th>
                    <th>Reporter Name</th>
                    <th>Reporter Phone</th>
                    <th>Status</th>
                    <th>Details</th>
                </thead>
                <tbody>
                <tr v-if="isFetchingReports">
                    <td colspan="8" style="text-align: center;"><i class="fa fa-spinner fa-spin fa-fw fa-2x"></i></td>
                    </tr>
                    <tr v-else-if="callReports.length === 0">
                    <td colspan="8" style="text-align: center;">No Cases Found</td>
                    </tr>
                    <tr v-else v-for="(item, idx) in callReports" :key="idx">
                    <slot :row="item">
                        <td>{{item.survivorName}}</td>
                        <td>{{item.survivorPhone}}</td>
                        <td>{{item.callHandlerName}}</td>
                        <td>{{item.created | formatDate}}</td>
                        <td>{{item.dateOfOccurrence | formatDate}}</td>
                        <td>{{item.lgaOfOccurrence}}</td>
                        <td>{{item.nature}}</td>
                        <td>{{item.reporterName}}</td>
                        <td>{{item.reporterPhone}}</td>
                        <td>{{item.status}}</td>
                        <td style="text-align:center">
                        <router-link :to="{path: `/reports/details/${item.id}`}"><i class="ti-new-window"></i></router-link>
                        </td>
                    </slot>
                    </tr>
                </tbody>
            </table>
            </div>
        </card>
    </div>
</template>
<script>
import moment from 'moment';
import {
    mapState,
    mapActions,
} from 'vuex';
import { getCounsellor } from "@/utils/auth";
export default {

  data() {
    return {
        config: {
            format: '',
            useCurrent: false,
            showClear: true,
            showClose: true,
        },
        search: null,
        filteredReports: [],
        filter: {
            workspaceId: null,
            phoneNumber: null,
        },
    };
  },
  computed: {
    ...mapState('cases', ['callReports', 'isFetchingCallReports']),
  },
  watch: {
  },
  filters: {
    formatDate(date) {
      let result = 'N/A';
      if (date) result = moment(date).format('MMMM Do YYYY, h:mm:ss a')
      return result;
    }
  },
  async created() {
    const counsellor = getCounsellor();
    console.log({counsellor});
    this.filter.workspaceId = counsellor.workspaceId;
    // await this.fetchWithFilter();
  },
    methods: {
        ...mapActions('cases', [
            'fetchCallReports',
        ]),
        async fetchWithFilter() {
          const params = this.filter;
          Object.keys(params).forEach((key) => (params[key] === null || params[key] === '') && delete params[key]);
          await this.fetchCallReports({params});
        },
        downloadWithFilter() {
            const params = {...this.filter};
            Object.keys(params).forEach((key) => (params[key] === null || params[key] === '') && delete params[key]);
            params.startDate = new Date(params.startDate).getTime();
            params.endDate = new Date(params.endDate).getTime();
            console.log(params)
            this.downloadReports(params);
        }
  }
};
</script>
<style lang="scss" scoped>
.side-actions{
    display: flex;
    align-items: flex-end;
    margin-left: auto;
    flex-direction: row-reverse;
    vertical-align: baseline;
    .dropdown{
        margin-bottom: 15px;
    }
}
.dropdown-menu{
    background-color: #fff !important;
    padding: 15px 0px !important;
    width: 300px;
}
</style>
