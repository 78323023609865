<template>
    <div class="row">
        <router-view></router-view>
    </div>
</template>
<script>
import CallsList from "./Calls/List.vue";
export default {
  components: {
    CallsList,
  }
};
</script>
<style lang="scss" scoped>
</style>
