import invoke from './invoke';

export const fetchData = async (data) => {
    try {
        const res = await invoke('POST', '/analytics/query', data);
        return res;
    } catch (ex) {
        throw ex;
    }
};
