<template>
    <div class="row">
    <div class="col-md-3">
      <fg-input type="text"
        placeholder="Search Cases"
        label="Search"
        v-model="search">
      </fg-input>
    </div>
    <div class="col-3">
        <fg-input-dropdown type="text"
            label="Case Type"
            placeholder="assignedCases"
            required
            :options="caseTypeOptions"
            v-model="caseType">
        </fg-input-dropdown>
    </div>

    <div class="col-3 side-actions"  v-if="counsellor.role === 'Administrator' || counsellor.role === 'Call Handler'" >
        <router-link class="btn btn-round btn-info" :to="{path:'/create'}">Add New Case</router-link>
    </div>
      <div class="col-12">
        <card :title="tableTitle">
          <div slot="raw-content" class="table-responsive">
            <!-- <paper-table :data="filteredData" :columns="tableColumns" :loading="isFetchingMyCases" /> -->
            <table class="table">
                <thead>
                    <th>Case(ID)</th>
                    <th>Call Handler</th>
                    <th>Created</th>
                    <th>Date Of Occurrence</th>
                    <th>LGA Of Occurrence</th>
                    <th>Nature</th>
                    <th>Referred By</th>
                    <th>State</th>
                    <th>Status</th>
                    <th>Details</th>
                </thead>
                <tbody>
                <tr v-if="isFetchingMyCases">
                    <td colspan="8" style="text-align: center;"><i class="fa fa-spinner fa-spin fa-fw fa-2x"></i></td>
                    </tr>
                    <tr v-else-if="filteredData.length === 0">
                    <td colspan="8" style="text-align: center;">No Cases Found</td>
                    </tr>
                    <tr v-else v-for="(item, idx) in filteredData" :key="idx">
                    <slot :row="item">
                        <td>{{item.id}}</td>
                        <td>{{item.callHandlerName}}</td>
                        <td>{{item.created | formatDate}}</td>
                        <td>{{item.dateOfOccurrence | formatDate}}</td>
                        <td>{{item.lgaOfOccurrence}}</td>
                        <td>{{item.nature}}</td>
                        <td>{{item.referredBy}}</td>
                        <td>{{item.stateIso2}}</td>
                        <td>{{item.status}}</td>
                        <td style="text-align:center">
                        <router-link :to="{path: getCaseDetailsPath(item.id)}"><i class="ti-new-window"></i></router-link>
                        </td>
                    </slot>
                    </tr>
                </tbody>
            </table>
          </div>
        </card>
      </div>
    </div>
</template>
<script>
import {
    mapState,
    mapActions,
} from 'vuex';
import moment from 'moment';
import { getCounsellor } from "@/utils/auth";
export default {
  components: {
  },
  data() {
    return {
      counsellor: {},
      caseType: 'assignedCases',
      data: [],
      search: null,
      filteredData: [],
      caseTypeOptions: [
            {id: 1,value: "assignedCases", text: "Assigned Cases"},
            {id: 2,value: "acceptedCases", text: "Accepted Cases"},
            {id: 3,value: "closedCaseCases", text: "Closed Cases"},
            {id: 4,value: "transferredCases", text: "Transferred Cases"}
        ],
      filter: {
        workspaceId: null,
        managerId: null,
        page: 0,
        count: 50,
      },
    };
  },
  computed: {
    ...mapState('cases', [
        'myCases', 'isFetchingMyCases'
    ]),
    tableTitle() {
            return (this.caseTypeOptions.find(o => o.value === this.caseType)).text;
        },

  },
  filters: {
       formatDate(date) {
          let result = 'N/A';
          if (date) result = moment(date).format('MMMM Do YYYY, h:mm:ss a')
          return result;
        }
  },
  watch: {
      caseType(newValue) {
          this.data = [...this.myCases[newValue]];
          console.log({myCases: this.myCases})
          this.search = null;
      },
      search(newValue){
          if (newValue && newValue !== '') {
            this.filteredData = this.data.filter(report=> {
              return report.nature.toLowerCase().includes(newValue) || report.status.toLowerCase().includes(newValue)
              || report.referredBy.toLowerCase().includes(newValue) || report.stateIso2.toLowerCase().includes(newValue)
              || report.lgaOfOccurrence.toLowerCase().includes(newValue)
          });
        } else {
            this.filteredData = this.data;
        }
      },
      data: {
        deep: true,
        handler(newValue) {
          this.filteredData = newValue;
        },
      },
  },
  async created() {
    this.counsellor = getCounsellor();
    this.filter.workspaceId = this.counsellor.workspaceId;
    this.filter.managerId = this.counsellor.counsellorId;
    await this.fetchWithFilter();
    this.data = this.myCases.assignedCases || [];
  },
  methods: {
     ...mapActions('cases', [
            'fetchMyCases',
        ]),
        getCaseDetailsPath(id) {
          let type;
          switch(this.caseType) {
            case "assignedCases":
              type = 'assigned';
            break;
            case "acceptedCases":
              type = 'accepted'
            break;
            case "closedCaseCases":
              type = 'closed'
            break;
            case "transferredCases":
              type = 'transferred'
            break;
            default:
              break;
          }
          return `/cases/${type}/${id}`;
        },
        async fetchWithFilter() {
          const params = this.filter;
          Object.keys(params).forEach((key) => (params[key] === null || params[key] === '') && delete params[key]);
          await this.fetchMyCases(params);
        },
  }
};
</script>
<style lang="scss" scoped>
.side-actions{
    display: flex;
    align-items: flex-end;
    margin-left: auto;
    flex-direction: row-reverse;
    vertical-align: baseline;
    a{
        margin-bottom: 15px;
    }
}
</style>
