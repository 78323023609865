<template>
  <card class="card">
    <div>
      <form id="registerForm" @submit.prevent="processForm">

        <div class="row">
          <div class="col-md-12">
            <fg-input type="text"
                      label="Email Address"
                      required
                      placeholder="john@doe.com"
                      v-model="user.email">
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <fg-input-dropdown type="text"
                label="Operating State"
                placeholder="e.g. Abuja"
                required
                :options="stateOptions"
                v-model="user.stateIso2">
            </fg-input-dropdown>
          </div>
        </div>
        <div v-if="requestError" class="row">
          <div class="col-md-12">
            <div class="alert alert-danger">
                <button type="button" aria-hidden="true" class="close" @click="closeAlert">×</button>
                <span>{{requestError}}</span>
            </div>
          </div>
        </div>

        <div class="text-center">
          <p-button type="success"
                    block
                    nativeType="submit">
            Register
          </p-button>
        </div>
        <div class="text-right">
            <router-link to="/user/login">Login to Workspace</router-link>
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </card>
</template>
<script>
import {
    mapState,
    mapActions,
} from 'vuex';
export default {
  data() {
    return {
      requestError: null,
        stateOptions: [
            {id: 1,value: "NG-LA", text: "Lagos"},
            {id: 2,value: "NG-FC", text: "Abuja"},
            {id: 3,value: "NG-AD", text: "Adamawa"}
        ],
        user: {
            email: null,
            stateIso2: null,
        },
    };
  },
  computed: {
    ...mapState('auth', [
        'workspaceSetup', 'isConfirmWorkspaceSetup'
    ]),
  },
  methods: {
    ...mapActions('auth', [
      'completeWorkspaceSetup',
    ]),
    closeAlert() {this.requestError = null},
    async processForm() {
        try {
            this.requestError = null;

            await this.completeWorkspaceSetup({ token: this.token });
            console.log({workspaceSetup: this.workspaceSetup})
            this.$router.push('/dashboard');
        } catch (ex) {
            this.requestError = ex;
            console.log(`error, ${ex}`);
        }
    },
  }
};
</script>
<style lang="scss" scoped>
    .card{
        box-shadow: none;
    }
</style>
