<template>
  <div class="col-12">
    <div class="row">
      <div class="col-md-3">
        <fg-input type="text"
          placeholder="Search Members"
          label="Search"
          v-model="search">
        </fg-input>
      </div>
      <div class="col-md-3 side-actions">
        <router-link class="btn btn-round btn-info" :to="{path:'/settings/password/change'}">Change My Password</router-link>
        <router-link v-if="counsellor.role === 'Administrator' || counsellor.role === 'SuperAdministrator'" class="btn btn-round btn-info" :to="{path:'/settings/create'}">Add New Agent</router-link>
      </div>
    </div>
    <card class="card" title="Members">
      <div>
        <table class="table">
          <thead>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Role</th>
            <th>Status</th>
            <th v-if="counsellor.role === 'Administrator' || counsellor.role === 'SuperAdministrator'">Actions</th>
          </thead>
          <tbody>
            <tr v-if="isFetchingCounsellorList">
              <td :colspan="counsellor.role === 'Administrator' ? 6 : 5" style="text-align: center;"><i class="fa fa-spinner fa-spin fa-fw fa-2x"></i></td>
              </tr>
              <tr v-else-if="filteredCounsellorList.length === 0">
              <td :colspan="counsellor.role === 'Administrator' ? 6 : 5" style="text-align: center;">No Members Found</td>
              </tr>
              <tr v-else v-for="(item, idx) in filteredCounsellorList" :key="idx">
                <slot :row="item">
                  <td>{{item.name}}</td>
                  <td>{{item.email}}</td>
                  <td>{{item.phone ? item.phone.toString(): item.phone}}</td>
                  <td>{{item.role}}</td>
                  <td>{{item.disabled ? 'Disabled' : 'Enabled'}}</td>
                  <td v-if="counsellor.role === 'Administrator' || counsellor.role === 'SuperAdministrator'" style="text-align:center">
                    <ul>
                      <drop-down class="nav-item"
                      title-classes="nav-link">
                        <a class="dropdown-item" href="#" v-on:click="editMember(item)">
                          Edit
                        </a>
                        <a class="dropdown-item" href="#" v-on:click="disableMemberById(item)">
                          {{ item.disabled ? "Enable" : "Disable"}}
                        </a>
                      </drop-down>
                    </ul>
                  </td>
                </slot>
              </tr>
          </tbody>
        </table>
      </div>
    </card>
  </div>
</template>
<script>
import {
    mapState,
    mapActions,
} from 'vuex';
import { getCounsellor } from "@/utils/auth";
export default {
  data() {
    return {
      search: null,
      counsellor: {},
      title: "Team members",
      filteredCounsellorList: [],
      filter: {
        workspaceId: null,
        page: 0,
        count: 100,
      }
    };
  },
  created() {
    this.counsellor = getCounsellor();
    this.filter.workspaceId = this.counsellor.workspaceId;
    this.fetchWithFilter();
  },
  watch: {
    search(newValue){
      if (newValue && newValue !== '') {
        this.filteredCounsellorList = this.counsellorList.filter(member=> {
          return member.name.toLowerCase().includes(newValue) || member.email.toLowerCase().includes(newValue)
          || member.phone.toString().toLowerCase().includes(newValue) || member.role.toLowerCase().includes(newValue)
        });
      } else {
          this.filteredCounsellorList = this.counsellorList;
      }
    },
    counsellorList(newValue) {
        this.filteredCounsellorList = newValue;
    }
  },
  computed: {
    ...mapState('auth', [
        'counsellorList', 'isFetchingCounsellorList'
    ]),
  },
  methods: {
    ...mapActions('auth', [
      'fetchCounsellorList', 'updateMemberInfo'
    ]),
     prevPage() {
      if (this.filter.page > 0) {
        this.filter.page -= 1;
        this.fetchWithFilter();
      }
    },
    nextPage() {
      if (this.counsellorList.length === this.filter.count) {
        this.filter.page += 1;
        this.fetchWithFilter();
      }
    },
    editMember(member) {
      this.$router.push(`/settings/create/${member.id}`);
    },
    async disableMemberById(member) {
      const counsellor = getCounsellor();
      console.log({ member });
      const params = {
        workspaceId: counsellor.workspaceId,
        requesterId: counsellor.counsellorId,
        emailToUpdate: member.email,
        disable: !member.disabled,
      }
      await this.updateMemberInfo(params);
      this.$notify({
        message: `Call Agent ${member.disabled ? 'Enabled' : 'Disabled'}!`,
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: 'success'
      });
      this.fetchWithFilter();
    },
    fetchWithFilter() {
      const params = this.filter;
      Object.keys(params).forEach((key) => (params[key] === null || params[key] === '') && delete params[key]);
      console.log(params)
      this.fetchCounsellorList(params);
    },
  }
};
</script>
<style lang="scss" scoped>
.side-actions{
    display: flex;
    align-items: flex-end;
    margin-left: auto;
    flex-direction: row-reverse;
    vertical-align: baseline;
    a{
        margin-bottom: 15px;
    }
}
</style>
