/* global pico */

import { saveAs } from 'file-saver';
import axios from 'axios';
import { EventBus } from '..';
import { getAccessToken } from '../auth';
import { APP_CONFIG } from '../';

const baseUrl  = APP_CONFIG.API_URL;
const clientId = APP_CONFIG.CLIENT_ID;
const timeout  = 10000;

export default async function invoke(
    method,
    path,
    data = undefined,
    options_ = {},
) {
    const options = Object.assign({
        baseUrl,
        headers: {},
    }, options_);

    const headers = {
        'X-Client-Id': `${clientId}`,
        'Origin': window.location.origin
    };

    const token = getAccessToken();
    if (token !== undefined) {
        headers.Authorization = `Bearer ${token}`;
    }

    if (options.headers) {
        Object.entries(options.headers)
            .forEach(([key, value]) => {
                headers[key] = value;
            });
    }

    const opts = {
        method,
        headers,
        mode: 'no-cors',
    };

    if (data) {
        if (opts.headers['Content-Type'] === undefined) {
            opts.headers['Content-Type'] = 'application/json';
            opts.body = JSON.stringify(data);
        }

        if (opts.headers['Content-Type'] === 'multipart/form-data') {
            delete opts.headers['Content-Type'];
            const formData = new FormData();
            Object.entries(data).forEach(([key, value]) => {
                formData.append(`${key}`, value);
            })
            opts.body = formData;
        }
    }

    let res;
    try {
        const url = `${baseUrl}${path}`
            .replace(/\+/, '%2B')
            .replace(/ /, '%20')
            .replace(/#/, '%23');
        const options = {
            url,
            method,
            data,
            timeout,

        }
        // res = await Promise.race([
        //     fetch(url, opts),
        //     new Promise((resolve, reject) => setTimeout(
        //         () => reject(new Error('timeout')), options.timeout || 25000,
        //     )),
        // ]);

        res = await axios(options);
    } catch (ex) {
        let result = ex;
        if (ex.response) {
            res = ex.response;
            if (res.status === 401 && token !== undefined) {
                EventBus.$emit('expiredToken');
                return null;
            }

            if (res.status === 500) {
                EventBus.$emit('serverError');
            }
            result = ex.response.data;
        }
        throw result;
    }

    return res.data;

    // if (!/\/export/.test(path)) { // assumes paths with no /export are not downloads
    //     const result = await res.json();
    //     if (result.metadata) {
    //         return {
    //             data: result.data,
    //             metadata: result.metadata,
    //         };
    //     }
    //     return result.data;
    // }
    // const blob = await res.blob();
    // console.log(res.headers.get('content-disposition'))
    // const filename = res.headers.get('content-disposition')
    //     .split(';')
    //     .find(n => n.includes('filename='))
    //     .replace('filename=', '')
    //     .trim()
    //     .replace(/(^"|"$)/g, '');
    // console.log(filename, blob, blob.type);
    // saveAs(blob, filename);
    // return true;
}
