<template>
    <div class="col-12">
        <div class="row">
            <div class="col-md-3">
                <fg-input type="text"
                        placeholder="Search Reports"
                        label="Search"
                        v-model="search">
                </fg-input>
            </div>
            <div class="col-6 side-actions">
                <div class="dropdown">
                    <button class="btn btn-round btn-info dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Export
                    </button>
                    <div class="dropdown-menu dropdown-menu-left p4">
                        <form class="col-12" @submit.prevent>
                            <div class="form-group">
                                <label>From</label>
                                <date-picker name="startDate" v-model="filter.startDate" :config="config"></date-picker>
                            </div>
                            <div class="form-group">
                                <label>To</label>
                                <date-picker name="endDate" v-model="filter.endDate" :config="config"></date-picker>
                            </div>
                            <p-button type="success"
                                    style="margin-left: auto; display: block;"
                                    :loading="isDownloadingReports"
                                    round
                                    @click.native.prevent="downloadWithFilter">
                            Download
                        </p-button>
                        </form>
                    </div>
                </div>
        </div>
        </div>
        <card title="Reports" subTitle="Case reports breakdown">
            <div slot="pagination">
                <a class="btn btn-just-icon btn-outline-default btn-sm" v-on:click="fetchWithFilter">
                <i class="ti-reload"></i>
                </a>
                &nbsp;
                &nbsp;
                &nbsp;
                page {{filter.page + 1}} of {{(filter.page * filter.count) + filteredReports.length}}
                &nbsp;
                <a class="btn btn-just-icon btn-outline-default btn-sm" :disabled="filteredReports.page <= 0" v-on:click="prevPage">
                <i class="ti-angle-left"></i>
                </a>
                &nbsp;
                <a class="btn btn-just-icon btn-outline-default btn-sm" :disabled="filteredReports.length !== filter.count" v-on:click="nextPage">
                <i class="ti-angle-right"></i>
                </a>
            </div>
            <div slot="raw-content" class="table-responsive">
            <!-- <paper-table :data="reports" :columns="tableColumns" :loading="isFetchingReports" /> -->
            <table class="table">
                <thead>
                    <th>Case(ID)</th>
                    <th>Call Handler</th>
                    <th>Created</th>
                    <th>Date Of Occurrence</th>
                    <th>LGA Of Occurrence</th>
                    <th>Nature</th>
                    <th>Survivor</th>
                    <th>Referred By</th>
                    <th>Status</th>
                    <th>Details</th>
                </thead>
                <tbody>
                <tr v-if="isFetchingReports">
                    <td colspan="8" style="text-align: center;"><i class="fa fa-spinner fa-spin fa-fw fa-2x"></i></td>
                    </tr>
                    <tr v-else-if="filteredReports.length === 0">
                    <td colspan="8" style="text-align: center;">No Cases Found</td>
                    </tr>
                    <tr v-else v-for="(item, idx) in filteredReports" :key="idx">
                    <slot :row="item">
                        <td>{{item.id}}</td>
                        <td>{{item.callHandlerName}}</td>
                        <td>{{item.created | formatDate}}</td>
                        <td>{{item.dateOfOccurrence | formatDate}}</td>
                        <td>{{item.lgaOfOccurrence}}</td>
                        <td>{{item.nature}}</td>
                        <td>{{item.survivorName}}</td>
                        <td>{{item.referredBy}}</td>
                        <td>{{item.status}}</td>
                        <td style="text-align:center">
                        <router-link :to="{path: `/reports/details/${item.id}`}"><i class="ti-new-window"></i></router-link>
                        </td>
                    </slot>
                    </tr>
                </tbody>
            </table>
            </div>
        </card>
    </div>
</template>
<script>
import moment from 'moment';
import {
    mapState,
    mapActions,
} from 'vuex';
import { getCounsellor } from "@/utils/auth";
export default {

  data() {
    return {
        config: {
            format: '',
            useCurrent: false,
            showClear: true,
            showClose: true,
        },
        search: null,
        filteredReports: [],
        filter: {
            startDate: new Date(),
            endDate: new Date(),
            workspaceId: null,
            counsellorId: null,
            page: 0,
            count: 1000,
        },
    };
  },
  computed: {
    ...mapState('cases', [
        'reports', 'isFetchingReports', 'reportCsv', 'isDownloadingReports'
    ]),
  },
  watch: {
      search(newValue){
          if (newValue && newValue !== '') {
            this.filteredReports = this.reports.filter(report=> {
              return report.nature.toLowerCase().includes(newValue) || report.status.toLowerCase().includes(newValue)
              || report.referredBy.toLowerCase().includes(newValue) || report.stateIso2.toLowerCase().includes(newValue)
              || report.lgaOfOccurrence.toLowerCase().includes(newValue)
          });
        } else {
            this.filteredReports = this.reports;
        }
      },
      reports(newValue) {
          this.filteredReports = newValue;
      }
  },
  filters: {
    formatDate(date) {
      let result = 'N/A';
      if (date) result = moment(date).format('MMMM Do YYYY, h:mm:ss a')
      return result;
    }
  },
  async created() {
    const counsellor = getCounsellor();
    console.log({counsellor});
    this.filter.workspaceId = counsellor.workspaceId;
    this.filter.counsellorId = counsellor.counsellorId;
    await this.fetchWithFilter();
  },
    methods: {
        ...mapActions('cases', [
            'fetchReports', 'downloadReports'
        ]),
        prevPage() {
          if (this.filter.page > 0) {
            this.filter.page -= 1;
            this.fetchWithFilter();
          }
        },
        nextPage() {
          if (this.reports.length === this.filter.count) {
            this.filter.page += 1;
            this.fetchWithFilter();
          }
        },
        async fetchWithFilter() {
          const params = this.filter;
          Object.keys(params).forEach((key) => (params[key] === null || params[key] === '') && delete params[key]);
          await this.fetchReports(params);
        },
        downloadWithFilter() {
            const params = {...this.filter};
            Object.keys(params).forEach((key) => (params[key] === null || params[key] === '') && delete params[key]);
            params.startDate = new Date(params.startDate).getTime();
            params.endDate = new Date(params.endDate).getTime();
            console.log(params)
            this.downloadReports(params);
        }
  }
};
</script>
<style lang="scss" scoped>
.side-actions{
    display: flex;
    align-items: flex-end;
    margin-left: auto;
    flex-direction: row-reverse;
    vertical-align: baseline;
    .dropdown{
        margin-bottom: 15px;
    }
}
.dropdown-menu{
    background-color: #fff !important;
    padding: 15px 0px !important;
    width: 300px;
}
</style>
