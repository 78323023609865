import { createWSConnection, createClient } from '@/utils/voiceClient';

const defaultState = {
    client: null,
    isCreatingClient: false,
    ws: null,
    isCreatingWS: false,
    capabilityToken: {},
};

const mutations = {
    setIsCreatingClient(state, status) {
        state.isCreatingClient = status;
    },
    setClient(state, client) {
        state.client = client;
    },
    setIsCreatingWs(state, status) {
        state.isCreatingWs = status;
    },
    setWs(state, ws) {
        state.ws = ws;
    },
    setCapabilityToken(state, capabilityToken) {
        state.capabilityToken = capabilityToken;
    },
};

const actions = {

    async initializeWs({ commit }, data) {
        try {
            commit('setIsCreatingWs', true);
            const ws = await createWSConnection(data);
            // console.log('initializeWs', data, ws)
            // console.log({ ws })
            if (data.isAgent) {
                let request = { "type": "GetCapabilityToken" };
                ws.send(JSON.stringify(request));
            }
            commit('setWs', ws);
            commit('setIsCreatingWs', false);
        } catch (ex) {
            throw ex;
        }
    },
    
    async initializeClient({ commit }, data) {
        try {
            commit('setClient', null);
            commit('setIsCreatingClient', true);
            const client = await createClient(data);
            commit('setClient', client);
            commit('setIsCreatingClient', false);
        } catch (ex) {
            commit('setIsCreatingClient', false);
            throw ex;
        }
    },

    async saveCapabilityToken({ commit }, data) {
        try {
            commit('setCapabilityToken', data);
        } catch (ex) {
            commit('setCapabilityToken', {});
        }
    }
};

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
};
