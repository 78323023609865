<template>
  <nav class="navbar navbar-expand-lg">
      <a class="navbar-brand" href="#">{{routeName}}</a>
    </nav>
</template>
<script>
export default {
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    }
  },
  data() {
    return {
      activeNotifications: false
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  }
};
</script>
<style lang="scss" scoped>
    .navbar {
        box-shadow: none;
        border-bottom: 1px solid #ccc;
        margin: 0 35px;
        .navbar-brand{
            margin: 0 auto;
            font-size: 26px;
        }
    }
</style>
