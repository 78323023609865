<template>
  <component :is="tag"
             class="dropdown"
             :class="{show:isOpen}"
             @click="toggleDropDown"
             v-click-outside="closeDropDown">
    <a class="dropdown-toggle btn-rotate"
       :class="titleClasses"
       data-toggle="dropdown">
      <slot name="title">
        <i :class="icon"></i>
        <span class="notification">{{title}}
          <b v-if="title" class="caret"></b>
          <i v-else class="ti-more-alt"></i>
        </span>
      </slot>
    </a>
    <ul class="dropdown-menu" :class="{show:isOpen}">
      <slot></slot>
    </ul>
  </component>
</template>
<script>
export default {
  props: {
    tag: {
      type: String,
      default: "li"
    },
    title: String,
    icon: String,
    titleClasses: [String, Object, Array]
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    toggleDropDown() {
      this.isOpen = !this.isOpen;
      this.$emit("change", this.isOpen);
    },
    closeDropDown() {
      this.isOpen = false;
      this.$emit('change', false);
    }
  }
};
</script>
<style lang="scss">
  td{
    .dropdown.nav-item{
      .dropdown-toggle::after{
        content: none !important;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none !important;
      &.dropdown-menu{
        min-width: fit-content;
        a.dropdown-item{
          text-align: center;
          padding: 10px 15px !important;
        }
      }
    }
  }
</style>
