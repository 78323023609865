<template>
    <div class="row">
        <router-view></router-view>
    </div>
</template>
<script>
import Graphs from "./Analytics/Graphs.vue";
export default {
  components: {
    Graphs,
  }
};
</script>
<style lang="scss" scoped>
</style>
