<template>
    <div class="row">
        <router-view></router-view>
    </div>
</template>
<script>
import CaseList from "./Reports/List.vue";
import CaseDetails from "./Reports/Details.vue";
export default {
  components: {
    CaseList,
    CaseDetails,
  }
};
</script>
<style lang="scss" scoped>
</style>
