import Africastalking from 'africastalking-client';
import { APP_CONFIG, EventBus } from './';
const wsUrl = APP_CONFIG.API_WS_URL;

export const createWSConnection = async (options) => {
    const { counsellorId } = options;
    //console.log(`${wsUrl, ['flex-protocol', counsellorId]}`);
    return new Promise((resolve, reject) => {
        createWSConnection.server = new WebSocket(wsUrl, ['flex-protocol', counsellorId ]);

        createWSConnection.server.onopen = () => {
            // console.log("Sending initial request");
            EventBus.$emit('handleWsOpen');
            resolve(createWSConnection.server);
        };

        createWSConnection.server.onmessage = (event) => {
            // console.log("Got this message from the websocket: " + event.data);
            EventBus.$emit('handleWsMessage', JSON.parse(event.data));
            resolve(createWSConnection.server);
        };

        createWSConnection.server.onclose = (err) => {
            // console.log("Lost connection to the gateway (is it down?)" + JSON.stringify(err));
            EventBus.$emit('handleWsClose');
            resolve(createWSConnection.server);
        };

        createWSConnection.server.onerror = (err) => {
            // console.error("Error: Error connecting to the WebSockets server... " + JSON.stringify(err));
            reject(err);
        };
    });
};

export const createClient = async (options) => {
    return new Promise((resolve, reject) => {

        createClient.client = new Africastalking.Client(options.token, {
            sounds: {
                dialing: '/dial.mp3',
                ringing: '/ring.mp3'
            }
        });

        createClient.client.on('ready', () => {
            console.log('om ready')
            options.handleReady(createClient.client);
        });

        createClient.client.on('calling', () => {
            console.log('calling')
            options.handleCalling(createClient.client);
        });

        createClient.client.on('incomingcall', (params) => {
            console.log('incomingcall')
            options.handleIncomingCall(params);
        });

        createClient.client.on('callaccepted', () => {
            console.log('callaccepted')
            options.handleCallAccepted(createClient.client);
        });


        createClient.client.on('hangup', (hangupCause) => {
            console.log('hangup')
            options.handleHangup(hangupCause);
        });

        createClient.client.on('missedcall', () => {
            console.log('missedcall')
            options.handleMissedCall(createClient.client);
        });

        createClient.client.on('notready', () => {
            console.log('not ready')
            options.handleNotReady(createClient.client);
        });

        createClient.client.on('offline', () => {
            console.log('oflinee')
            options.handleOffline(createClient.client);
        });

        createClient.client.on('closed', () => {
            console.log('closedd')
            options.handleClosed(createClient.client);
        });

        resolve(createClient.client);
    });
};

