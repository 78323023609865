<template>
  <card class="card">
    <div>
      <form id="registerForm" @submit.prevent="processForm">

       <div class="row">
          <div class="col-md-12">
            <fg-input type="password"
                      label="New Password"
                      required
                      placeholder="mysecretpassword"
                      v-model="user.password">
            </fg-input>
          </div>
        </div>

       <div class="row">
          <div class="col-md-12">
            <fg-input type="password"
                      label="Confirm Password"
                      required
                      placeholder="mysecretpassword"
                      v-model="user.confirmPassword">
            </fg-input>
          </div>
        </div>
        <div v-if="signUpError" class="row">
          <div class="col-md-12">
            <div class="alert alert-danger">
                <button type="button" aria-hidden="true" class="close" @click="closeAlert">×</button>
                <span>{{signUpError}}</span>
            </div>
          </div>
        </div>

        <div class="text-center">
          <p-button type="success"
                    block
                    nativeType="submit">
            Complete Sign up
          </p-button>
        </div>
        <div class="text-right">
            <router-link to="/user/login">Login to Workspace</router-link>
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </card>
</template>
<script>
import {
    mapState,
    mapActions,
} from 'vuex';
export default {
  data() {
    return {
        token: null,
        signUpError: null,
        user: {
            password: null,
            confirmPassword: null,
        },
    };
  },
  created() {
      this.token = this.$route.params.token;
  },
   computed: {
        ...mapState('auth', [
            'isSigningUp',
        ]),
    },

    methods: {
        ...mapActions('auth', [
            'signUp',
        ]),

        closeAlert() {this.signUpError = null},

        async processForm() {
            try {
                this.signUpError = null;

                if (this.user.password !== this.user.confirmPassword) {
                    this.signUpError = `Passwords are not the same`;
                    return;
                }

                await this.signUp({
                    token: this.token,
                    password: this.user.password,
                });
                this.$router.push('/dashboard');
            } catch (ex) {
                this.signUpError = ex;
                console.log(ex);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
    .card{
        box-shadow: none;
    }
</style>
