import invoke from './invoke';

export const fetchReportsData = async ({ workspaceId, page, count}) => {
    try {
        const res = await invoke('GET', `/cases/report/${workspaceId}/${page}/${count}`);
        return res;
    } catch (ex) {
        throw ex;
    }
};

export const downloadReportsData = async ({ workspaceId, counsellorId, startDate, endDate, }) => {
    try {
        const res = await invoke('GET', `/cases/export/${workspaceId}/${counsellorId}/${startDate}/${endDate}`);
        return res;
    } catch (ex) {
        throw ex;
    }
};

export const findReportByPhoneNumber = async ({ workspaceId, phoneNumber }) => {
    try {
        const res = await invoke('GET', `/cases/find/match/${workspaceId}/${phoneNumber}`);
        return res;
    } catch (ex) {
        throw ex;
    }
};

export const fetchReportDetailsData = async ({ workspaceId, id }) => {
    try {
        const res = await invoke('GET', `/cases/detail/${workspaceId}/${id}`);
        return res;
    } catch (ex) {
        throw ex;
    }
};

export const fetchMyCasesData = async ({ workspaceId, managerId }) => {
    try {
        const res = await invoke('GET', `/cases/mycases/${workspaceId}/${managerId}`);
        return res;
    } catch (ex) {
        throw ex;
    }
};

export const fetchCasesCountData = async ({ workspaceId }) => {
    try {
        const res = await invoke('GET', `/cases/status/count/${workspaceId}`);
        return res;
    } catch (ex) {
        throw ex;
    }
};

export const fetchDashboardMetricsCount = async ({ workspaceId }) => {
  try {
    const res = await invoke('GET', `/metrics/count/${workspaceId}`);
    return res;
  } catch (ex) {
    throw ex;
  }
};

export const submitCaseData = async (data) => {
    try {
        const res = await invoke('POST', '/cases/submit', data);
        return res;
    } catch (ex) {
        throw ex;
    }
};


export const transferCase = async (data) => {
    try {
        const res = await invoke('POST', '/cases/transfer', data);
        return res;
    } catch (ex) {
        throw ex;
    }
};

export const closeCase = async (data) => {
    try {
        const res = await invoke('POST', '/cases/close', data);
        return res;
    } catch (ex) {
        throw ex;
    }
};

export const submitCaseNote = async (data) => {
    try {
        const res = await invoke('POST', '/cases/add/note', data);
        return res;
    } catch (ex) {
        throw ex;
    }
};

export const acceptCase = async (data) => {
    try {
        const res = await invoke('POST', '/cases/accept', data);
        return res;
    } catch (ex) {
        throw ex;
    }
};
