<template>
  <card class="card">
    <div>
      <form id="registerForm" @submit.prevent="processForm">
        <div class="text-center">
          <p-button type="success"
                    block
                    :loading="isConfirmWorkspaceSetup"
                    nativeType="submit">
            Complete Workspace setup
          </p-button>
        </div>
        <div v-if="confirmError" class="row">
          <div class="col-md-12">
            <div class="alert alert-danger">
                <button type="submit" aria-hidden="true" class="close" @click="closeAlert">×</button>
                <span>{{confirmError}}</span>
            </div>
          </div>
        </div>
        <div class="text-right">
            <router-link to="/user/login">Login to Workspace</router-link>
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </card>
</template>
<script>
import {
    mapState,
    mapActions,
} from 'vuex';
export default {
  data() {
    return {
        confirmError: null,
        token: null,
    };
  },
  created() {
      this.token = this.$route.params.token;
  },
  computed: {
    ...mapState('auth', [
        'workspaceSetup', 'isConfirmWorkspaceSetup'
    ]),
},
  methods: {
     ...mapActions('auth', [
            'signIn', 'completeWorkspaceSetup'
        ]),
        closeAlert() {this.confirmError = null},
    async processForm() {
        try {
            this.confirmError = null;

            await this.completeWorkspaceSetup({ token: this.token });
            console.log({workspaceSetup: this.workspaceSetup})
            this.$router.push('/dashboard');
        } catch (ex) {
            this.confirmError = ex;
            console.log(`error, ${ex}`);
        }
    },
  }
};
</script>
<style lang="scss" scoped>
    .card{
        box-shadow: none;
    }
</style>
