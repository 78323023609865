import moment from "moment/moment";

export const twoWeeksMillis = 1209600000;

export const oneDayMillis = 86400000;

export const oneMonthMillis = 2629800000;

export function minDate() {
  return moment("2021-01-01").startOf('day').format('YYYY-MM-DD');
}
export function nowDate() {
  return moment().format('YYYY-MM-DD');
}

export function twoWeekStartDate() {
  return moment().startOf('day').subtract(2, 'weeks').format('YYYY-MM-DD');
}
export function twoWeekEndDate() {
  return moment().endOf('day').format('YYYY-MM-DD');
}

export function oneDayAgoStartDate() {
  return moment().startOf('day').subtract(1, 'days').format('YYYY-MM-DD');
}
export function endOfTodayEndDate() {
  return moment().endOf('day').format('YYYY-MM-DD');
}

export function isOneDay(
  startMomentDate,
  endMomentDate
) {
  return moment(endMomentDate).isSame(moment(startMomentDate), 'd');
}

export function isMoreThanAMonth (
  startMomentDate,
  endMomentDate
) {
  const start = moment(startMomentDate);
  const end = moment(endMomentDate);
  return end.diff(start, 'days') > 30;
}

